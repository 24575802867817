import React, { Component } from 'react'
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import SportsIcon from '@material-ui/icons/Sports';
import CloudIcon from '@material-ui/icons/Cloud';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import SportsKabaddiIcon from '@material-ui/icons/SportsKabaddi';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import Filter9Icon from '@material-ui/icons/Filter9';


export default class GameStatusLabel extends Component {
    constructor(props){
        super(props);
    
        this.state = {
        }
    }

    render() {
        var labelStyle;
        if (this.props.sports === 'NHL') {
            labelStyle = (this.props.game.labels.lineups === 'revealed')? 'goodLabelStyle': 'badLabelStyle';
            // labelStyle = (this.props.game.labels.lineups === 'revealed' && this.props.game.labels.cl && this.props.game.labels.sr && this.props.game.labels.to && this.props.game.labels.pp && this.props.game.labels.sc)? 'goodLabelStyle': 'badLabelStyle';
            // labelStyle = (!this.props.game.labels.injuries && this.props.game.labels.lineups === 'revealed' && this.props.game.labels.goalies && this.props.game.labels.matchups)? 'goodLabelStyle': 'badLabelStyle';
            return (
                <div className={labelStyle}>
                    {
                    this.props.game.labels.lineups === "revealed"?
                        <Tooltip title="Lineups Revealed" arrow>
                            <Avatar className="okLabel">
                                <AssignmentTurnedInIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {
                    this.props.game.labels.lineups === "missing"?
                        <Tooltip title="Missing Lineups" arrow>
                            <Avatar className="nokayLabel">
                                <AssignmentTurnedInIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {/* {
                    this.props.game.labels.lineups === "missing"?
                        <Tooltip title="Missing Lineups" arrow>
                            <Avatar className="nokayLabel">
                                <AssignmentTurnedInIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {
                    this.props.game.labels.lineups === "revised"?
                        <Tooltip title="Lineups Revised" arrow>
                            <Avatar className="nokayLabel">
                                <AssignmentLateIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {
                    this.props.game.labels.cl?
                        <Tooltip title="Closing Lines Inputted" arrow>
                            <Avatar className="okLabel">
                                <FeaturedPlayListIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Closing Lines" arrow>
                            <Avatar className="nokayLabel">
                                <FeaturedPlayListIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.sr?
                        <Tooltip title="Stats Ready" arrow>
                            <Avatar className="okLabel">
                                <ShowChartIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Stats to be confirmed" arrow>
                            <Avatar className="nokayLabel">
                                <ShowChartIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.to?
                        <Tooltip title="Team Odds Released" arrow>
                            <Avatar className="okLabel">
                                <EmojiEventsIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Team Odds" arrow>
                            <Avatar className="nokayLabel">
                                <EmojiEventsIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.pp?
                        <Tooltip title="Player Props Released" arrow>
                            <Avatar className="okLabel">
                                <SportsKabaddiIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Player Props" arrow>
                            <Avatar className="nokayLabel">
                                <SportsKabaddiIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.sc?
                        <Tooltip title="Checked with other bookmakers" arrow>
                            <Avatar className="okLabel">
                                <PlaylistAddCheckIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing check with other bookmarkers" arrow>
                            <Avatar className="nokayLabel">
                                <PlaylistAddCheckIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    } */}
                    {/* {
                    this.props.game.labels.goalies?
                        <Tooltip title="Goalies Revealed" arrow>
                            <Avatar className="okLabel">G</Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Goalies" arrow>
                            <Avatar className="nokayLabel">G</Avatar>
                        </Tooltip>

                    }
                    {
                    this.props.game.labels.injuries?
                        <Tooltip title="Player Injured" arrow>
                            <Avatar className="nokayLabel">
                                <LocalHospitalIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="No Injuries" arrow>
                            <Avatar className="okLabel">
                                <LocalHospitalIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>

                    }
                    {
                    this.props.game.labels.matchups?
                        <Tooltip title="Matchups Revealed" arrow>
                            <Avatar className="okLabel">M</Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Matchups" arrow>
                            <Avatar className="nokayLabel">M</Avatar>
                        </Tooltip>
                    } */}
                </div>
            )
        } else if (this.props.sports === 'NBA' || this.props.sports === 'CBB') {
            // labelStyle = (this.props.game.labels.lineups === 'revealed')? 'goodLabelStyle': 'badLabelStyle';
            labelStyle = (this.props.game.labels.lineups === 'revealed')? 'goodLabelStyle': 'badLabelStyle';
            // labelStyle = (this.props.game.labels.lineups === 'revealed' && this.props.game.labels.cl && this.props.game.labels.sr && this.props.game.labels.to && this.props.game.labels.pp && this.props.game.labels.sc)? 'goodLabelStyle': 'badLabelStyle';
            // labelStyle = (!this.props.game.labels.injuries && this.props.game.labels.lineups === 'revealed' && this.props.game.labels.matchups)? 'goodLabelStyle': 'badLabelStyle';
            return (
                <div className={labelStyle}>
                    {
                    this.props.game.labels.lineups === "revealed"?
                        <Tooltip title="Lineups Revealed" arrow>
                            <Avatar className="okLabel">
                                <AssignmentTurnedInIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {
                    this.props.game.labels.lineups === "missing"?
                        <Tooltip title="Missing Lineups" arrow>
                            <Avatar className="nokayLabel">
                                <AssignmentTurnedInIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {/* {
                    this.props.game.labels.lineups === "revised"?
                        <Tooltip title="Lineups Revised" arrow>
                            <Avatar className="nokayLabel">
                                <AssignmentLateIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {
                    this.props.game.labels.cl?
                        <Tooltip title="Closing Lines Inputted" arrow>
                            <Avatar className="okLabel">
                                <FeaturedPlayListIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Closing Lines" arrow>
                            <Avatar className="nokayLabel">
                                <FeaturedPlayListIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.sr?
                        <Tooltip title="Stats Ready" arrow>
                            <Avatar className="okLabel">
                                <ShowChartIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Stats to be confirmed" arrow>
                            <Avatar className="nokayLabel">
                                <ShowChartIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.to?
                        <Tooltip title="Team Odds Released" arrow>
                            <Avatar className="okLabel">
                                <EmojiEventsIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Team Odds" arrow>
                            <Avatar className="nokayLabel">
                                <EmojiEventsIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.pp?
                        <Tooltip title="Player Props Released" arrow>
                            <Avatar className="okLabel">
                                <SportsKabaddiIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Player Props" arrow>
                            <Avatar className="nokayLabel">
                                <SportsKabaddiIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.sc?
                        <Tooltip title="Checked with other bookmakers" arrow>
                            <Avatar className="okLabel">
                                <PlaylistAddCheckIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing check with other bookmarkers" arrow>
                            <Avatar className="nokayLabel">
                                <PlaylistAddCheckIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    } */}
                    {/* {
                    this.props.game.labels.injuries?
                        <Tooltip title="Player Injured" arrow>
                            <Avatar className="nokayLabel">
                                <LocalHospitalIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="No Injuries" arrow>
                            <Avatar className="okLabel">
                                <LocalHospitalIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>

                    }
                    {
                    this.props.game.labels.matchups?
                        <Tooltip title="Matchups Revealed" arrow>
                            <Avatar className="okLabel">M</Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Matchups" arrow>
                            <Avatar className="nokayLabel">M</Avatar>
                        </Tooltip>
                    } */}
                </div>
            )
        } else if (this.props.sports === 'MLB') {
            labelStyle = (this.props.game.labels.lineups === 'revealed')? 'goodLabelStyle': 'badLabelStyle';
            // labelStyle = (!this.props.game.labels.injuries && this.props.game.labels.lineups === 'revealed' && this.props.game.labels.umpires && this.props.game.labels.weather && this.props.game.labels.matchups)? 'goodLabelStyle': 'badLabelStyle';
            // labelStyle = (this.props.game.labels.sp && this.props.game.labels.bo && this.props.game.labels.cl && this.props.game.labels.sr && this.props.game.labels.to && this.props.game.labels.pp && this.props.game.labels.sc)? 'goodLabelStyle': 'badLabelStyle';
            return (
                <div className={labelStyle}>
                    {
                    this.props.game.labels.lineups === "revealed"?
                        <Tooltip title="Lineups have been published" arrow>
                            <Avatar className="okLabel">
                                <AssignmentTurnedInIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {
                    this.props.game.labels.lineups === "missing"?
                        <Tooltip title="Missing Lineups" arrow>
                            <Avatar className="nokayLabel">
                                <AssignmentTurnedInIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {/* {
                    this.props.game.labels.lineups === "missing"?
                        <Tooltip title="Missing Lineups" arrow>
                            <Avatar className="nokayLabel">
                                <AssignmentTurnedInIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {
                    this.props.game.labels.lineups === "revised"?
                        <Tooltip title="Lineups Revised" arrow>
                            <Avatar className="nokayLabel">
                                <AssignmentLateIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    } */}
                    {/* {
                    this.props.game.labels.sp?
                        <Tooltip title="Starting Pitcher Published" arrow>
                            <Avatar className="okLabel">
                                <SportsHandballIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Starting Pitcher" arrow>
                            <Avatar className="nokayLabel">
                                <SportsHandballIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.bo?
                        <Tooltip title="Batting Order Published" arrow>
                            <Avatar className="okLabel">
                                <Filter9Icon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Batting Order" arrow>
                            <Avatar className="nokayLabel">
                                <Filter9Icon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.cl?
                        <Tooltip title="Closing Lines Inputted" arrow>
                            <Avatar className="okLabel">
                                <FeaturedPlayListIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Closing Lines" arrow>
                            <Avatar className="nokayLabel">
                                <FeaturedPlayListIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.sr?
                        <Tooltip title="Stats Ready" arrow>
                            <Avatar className="okLabel">
                                <ShowChartIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Stats to be confirmed" arrow>
                            <Avatar className="nokayLabel">
                                <ShowChartIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.to?
                        <Tooltip title="Team Odds Released" arrow>
                            <Avatar className="okLabel">
                                <EmojiEventsIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Team Odds" arrow>
                            <Avatar className="nokayLabel">
                                <EmojiEventsIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.pp?
                        <Tooltip title="Player Props Released" arrow>
                            <Avatar className="okLabel">
                                <SportsKabaddiIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Player Props" arrow>
                            <Avatar className="nokayLabel">
                                <SportsKabaddiIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.sc?
                        <Tooltip title="Checked with other bookmakers" arrow>
                            <Avatar className="okLabel">
                                <PlaylistAddCheckIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing check with other bookmarkers" arrow>
                            <Avatar className="nokayLabel">
                                <PlaylistAddCheckIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    } */}
                    {/* {
                    this.props.game.labels.umpires?
                        <Tooltip title="Umpires Revealed" arrow>
                            <Avatar className="okLabel">
                                <SportsIcon style={{fontSize: '16px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Umpires" arrow>
                            <Avatar className="nokayLabel">
                                <SportsIcon style={{fontSize: '16px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.weather?
                        <Tooltip title="Weather Info Revealed" arrow>
                            <Avatar className="okLabel">
                                <CloudIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Weather Info" arrow>
                            <Avatar className="nokayLabel">
                                <CloudIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.injuries?
                        <Tooltip title="Player Injured" arrow>
                            <Avatar className="nokayLabel">
                                <LocalHospitalIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="No Injuries" arrow>
                            <Avatar className="okLabel">
                                <LocalHospitalIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>

                    }
                    {
                    this.props.game.labels.matchups?
                        <Tooltip title="Matchups Revealed" arrow>
                            <Avatar className="okLabel">M</Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Matchups" arrow>
                            <Avatar className="nokayLabel">M</Avatar>
                        </Tooltip>
                    } */}
                </div>
            )
        } else if (this.props.sports === 'NFL' || this.props.sports === 'CFB') {
            // labelStyle = (this.props.game.labels.lineups === 'revealed' && this.props.game.labels.cl && this.props.game.labels.sr && this.props.game.labels.to && this.props.game.labels.pp && this.props.game.labels.sc)? 'goodLabelStyle': 'badLabelStyle';
            labelStyle = (this.props.game.labels.lineups === 'revealed')? 'goodLabelStyle': 'badLabelStyle';
            // labelStyle = (!this.props.game.labels.injuries && this.props.game.labels.lineups === 'revealed' && this.props.game.labels.weather && this.props.game.labels.matchups)? 'goodLabelStyle': 'badLabelStyle';
            return (
                <div className={labelStyle}>
                    {
                    this.props.game.labels.lineups === "revealed"?
                        <Tooltip title="Lineups Revealed" arrow>
                            <Avatar className="okLabel">
                                <AssignmentTurnedInIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Lineups" arrow>
                            <Avatar className="nokayLabel">
                                <AssignmentTurnedInIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>

                    }
                    {/* {
                    this.props.game.labels.lineups === "missing"?
                        <Tooltip title="Missing Lineups" arrow>
                            <Avatar className="nokayLabel">
                                <AssignmentTurnedInIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {
                    this.props.game.labels.lineups === "revised"?
                        <Tooltip title="Lineups Revised" arrow>
                            <Avatar className="nokayLabel">
                                <AssignmentLateIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {
                    this.props.game.labels.cl?
                        <Tooltip title="Closing Lines Inputted" arrow>
                            <Avatar className="okLabel">
                                <FeaturedPlayListIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Closing Lines" arrow>
                            <Avatar className="nokayLabel">
                                <FeaturedPlayListIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.sr?
                        <Tooltip title="Stats Ready" arrow>
                            <Avatar className="okLabel">
                                <ShowChartIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Stats to be confirmed" arrow>
                            <Avatar className="nokayLabel">
                                <ShowChartIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.to?
                        <Tooltip title="Team Odds Released" arrow>
                            <Avatar className="okLabel">
                                <EmojiEventsIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Team Odds" arrow>
                            <Avatar className="nokayLabel">
                                <EmojiEventsIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.pp?
                        <Tooltip title="Player Props Released" arrow>
                            <Avatar className="okLabel">
                                <SportsKabaddiIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Player Props" arrow>
                            <Avatar className="nokayLabel">
                                <SportsKabaddiIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.sc?
                        <Tooltip title="Checked with other bookmakers" arrow>
                            <Avatar className="okLabel">
                                <PlaylistAddCheckIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing check with other bookmarkers" arrow>
                            <Avatar className="nokayLabel">
                                <PlaylistAddCheckIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.weather?
                        <Tooltip title="Weather Info Revealed" arrow>
                            <Avatar className="okLabel">
                                <CloudIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Weather Info" arrow>
                            <Avatar className="nokayLabel">
                                <CloudIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.injuries?
                        <Tooltip title="Player Injured" arrow>
                            <Avatar className="nokayLabel">
                                <LocalHospitalIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="No Injuries" arrow>
                            <Avatar className="okLabel">
                                <LocalHospitalIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>

                    }
                    {
                    this.props.game.labels.matchups?
                        <Tooltip title="Matchups Revealed" arrow>
                            <Avatar className="okLabel">M</Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Matchups" arrow>
                            <Avatar className="nokayLabel">M</Avatar>
                        </Tooltip>
                    } */}
                </div>
            )
        } else if (this.props.sports === 'WNBA') {
            labelStyle = (this.props.game.labels.lineups === 'revealed' && this.props.game.labels.cl && this.props.game.labels.sr && this.props.game.labels.to && this.props.game.labels.pp && this.props.game.labels.sc)? 'goodLabelStyle': 'badLabelStyle';
            // labelStyle = (!this.props.game.labels.injuries && this.props.game.labels.lineups === 'revealed' && this.props.game.labels.matchups)? 'goodLabelStyle': 'badLabelStyle';
            // labelStyle = (this.props.game.labels.lineups === 'revealed')? 'goodLabelStyle': 'badLabelStyle';
            return (
                <div className={labelStyle}>
                    {
                    this.props.game.labels.lineups === "revealed"?
                        <Tooltip title="Lineups Revealed" arrow>
                            <Avatar className="okLabel">
                                <AssignmentTurnedInIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {
                    this.props.game.labels.lineups === "missing"?
                        <Tooltip title="Missing Lineups" arrow>
                            <Avatar className="nokayLabel">
                                <AssignmentTurnedInIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {
                    this.props.game.labels.lineups === "revised"?
                        <Tooltip title="Lineups Revised" arrow>
                            <Avatar className="nokayLabel">
                                <AssignmentLateIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        null

                    }
                    {
                    this.props.game.labels.cl?
                        <Tooltip title="Closing Lines Inputted" arrow>
                            <Avatar className="okLabel">
                                <FeaturedPlayListIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Closing Lines" arrow>
                            <Avatar className="nokayLabel">
                                <FeaturedPlayListIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.sr?
                        <Tooltip title="Stats Ready" arrow>
                            <Avatar className="okLabel">
                                <ShowChartIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Stats to be confirmed" arrow>
                            <Avatar className="nokayLabel">
                                <ShowChartIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.to?
                        <Tooltip title="Team Odds Released" arrow>
                            <Avatar className="okLabel">
                                <EmojiEventsIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Team Odds" arrow>
                            <Avatar className="nokayLabel">
                                <EmojiEventsIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.pp?
                        <Tooltip title="Player Props Released" arrow>
                            <Avatar className="okLabel">
                                <SportsKabaddiIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Player Props" arrow>
                            <Avatar className="nokayLabel">
                                <SportsKabaddiIcon style={{fontSize: '13px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {
                    this.props.game.labels.sc?
                        <Tooltip title="Checked with other bookmakers" arrow>
                            <Avatar className="okLabel">
                                <PlaylistAddCheckIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing check with other bookmarkers" arrow>
                            <Avatar className="nokayLabel">
                                <PlaylistAddCheckIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>
                    }
                    {/* {
                    this.props.game.labels.injuries?
                        <Tooltip title="Player Injured" arrow>
                            <Avatar className="nokayLabel">
                                <LocalHospitalIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>:
                        <Tooltip title="No Injuries" arrow>
                            <Avatar className="okLabel">
                                <LocalHospitalIcon style={{fontSize: '14px'}}/>
                            </Avatar>
                        </Tooltip>

                    }
                    {
                    this.props.game.labels.matchups?
                        <Tooltip title="Matchups Revealed" arrow>
                            <Avatar className="okLabel">M</Avatar>
                        </Tooltip>:
                        <Tooltip title="Missing Matchups" arrow>
                            <Avatar className="nokayLabel">M</Avatar>
                        </Tooltip>
                    } */}
                </div>
            )
        }
    }
}
